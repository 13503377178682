import React, { useState, useEffect } from "react";
import './Ordercard.css'; // Import your CSS file for styling
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from './TranslationContext';


const Ordercard = () => {
  const [cards, setCards] = useState([]);
  const [cards1, setCards1] = useState({ totalamt: 0, ordertotal: 0, offer: 0 });
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const [addressId, setAddressId] = useState(null);
  const [coupons, setCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(cards1.totalamt);


  const [quantity, setQuantity] = useState(1);
  // setQuantity(product.quantity);

  const handleQuantityChange = (productId, newQuantity) => {
    setCards(prevCards => prevCards.map(card => {
      if (card.product_id === productId) {
        return { ...card, quantity: newQuantity };
      }
      return card;
    }));
  };

   // Increase quantity of a product in the cart
   const increaseQuantity = async (product_id, currentQuantity, cart_id, price,qty) => {
    try {
      
        const newQuantity = currentQuantity + 1;
        if (newQuantity <= qty) {
        const newprice = price * newQuantity;

        const api_url = localStorage.getItem('api_url');
        const response = await axios.post(api_url + '/updateproductquantity1', {
          product_id,
          cart_id,
          remain_qty: newQuantity,
          price
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.data.status) {
          setCards(prevCards => prevCards.map(card =>
            card.product_id === product_id ? { ...card, quantity: newQuantity } : card
          ));
          window.location.reload();
        }
      }else{
        alert("Quantity limit is over");
      }
      
    } catch (error) {
      console.error("Error increasing quantity:", error);
    }
  };

  // Decrease quantity of a product in the cart
  const decreaseQuantity = async (product_id, currentQuantity, cart_id, price) => {
    try {
      if (currentQuantity > 1) {
        const newQuantity = currentQuantity - 1;
        const newprice = price * newQuantity;

        const api_url = localStorage.getItem('api_url');
        const response = await axios.post(api_url + '/updateproductquantity1', {
          product_id,
          cart_id,
          remain_qty: newQuantity,
          price
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.data.status) {
          setCards(prevCards => prevCards.map(card =>
            card.product_id === product_id ? { ...card, quantity: newQuantity } : card
          ));
          window.location.reload();
        }
      }else{
        alert("quantity atleast select 1 ")
      }
    } catch (error) {
      console.error("Error decreasing quantity:", error);
    }
  };


  

  const handleSavetrans_pament = async (price) => {
    try {
      const user_id = localStorage.getItem('admin_id'); 
      const api_url = localStorage.getItem('api_url');
      const response1 = await axios.get(api_url + '/getusercarts/' + user_id);
        for (const res of response1.data.cartItems) {
          if (res.product_id.prescription === 1 && !res.precription_image) {
            alert("Kindly upload prescription");
            window.location.reload();
          }
        }







      const cart_id = cards.map(card => card.cart_id).join(", ");
      
       const total_amt = price;
      const dateString = new Date();// Example date string
      const timestamp = Date.parse(dateString);
      const receiptid = Math.random().toString(36).substring(7);
      const transactionid = receiptid+'-'+timestamp;
      const currentDate = getCurrentDateTime;

      const response_add = await axios.get(`${api_url}/getAddressId/${user_id}`);
      if (response_add.data.address_id) {
        const address_id = response_add.data.address_id;
      
        const response = await axios.post(api_url + '/savetrancations', {
          user_id,
          cart_id,
          total_amt,
          transactionid,
          address_id
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          transformRequest: [(data, headers) => {
            const params = new URLSearchParams();
            for (const key in data) {
              params.append(key, data[key]);
            }
            return params.toString();
          }],
        });
      
       
      const check_res = response.data.receipt_id; 
      const user_detail = response.data.user_details;
      const add_detail = response.data.address;
  
      if (check_res) {
        
        const tot_amt = total_amt * 100;
        // const response = await axios.post(api_url +'/paymentorder');

        // const user_detail = await U.findOne({ _id: req.params.subcategory_id }).lean();
        
        const response = await axios.post(api_url + '/paymentorder', {
          user_id,
          cart_id,
          tot_amt,
          transactionid,
          address_id
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          transformRequest: [(data, headers) => {
            const params = new URLSearchParams();
            for (const key in data) {
              params.append(key, data[key]);
            }
            return params.toString();
          }],
        });




        const orderId  = await response.data.payment_id;
 
        const options = {
          key: 'rzp_test_JFlMQ0tOgzb3j8',
          amount: tot_amt, // amount in paise (50000 paise = ₹500)
          currency: 'INR',
          name: 'Medcall',
          receipt_no:check_res,
          description: 'Medcall Transactions',
          image: 'https://example.com/your_logo',
          order_id: orderId,
          handler: async function (response) {
           console.log(response.razorpay_payment_id);
           const rez_p = response.razorpay_payment_id;
           const status = 'purchased';

           const response2 = await axios.post(api_url + '/paymentorderupdate', {
            tot_amt,
            transactionid,
            rez_p,
            status,
            cart_id
            
          }, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [(data, headers) => {
              const params = new URLSearchParams();
              for (const key in data) {
                params.append(key, data[key]);
              }
              return params.toString();
            }],
          });


          const payment_status = response2.data.status;

          if(payment_status){
            navigate("/Orderplace", { replace: true });
            alert('payment process succussfully completed');
          }




  
          },
          prefill: {
            name: user_detail.firstname,
            email: user_detail.email,
            contact: user_detail.phone_number,
          },
          notes: {
            address: add_detail.landmark+','+add_detail.building+','+add_detail.area+','+add_detail.district+','+add_detail.state+','+add_detail.country+' - '+add_detail.pincode,
          },
          theme: {
            color: '#3399cc',
          },
        };
 
        const rzp1 = new window.Razorpay(options);
        rzp1.open();

         
      }

       // Handle response
      } else {
        alert("Please add address and place order");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  const getCurrentDateTime = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  // const handleSaveToCart = async (cart_id,id,price1,qty) => {
  //   try {
  //     const product_id = id; 
  //     const user_id = localStorage.getItem('admin_id'); 
  //     const quantity = qty;
  //     const price = price1;
  
  //     const api_url = localStorage.getItem('api_url');
  //     const response = await axios.post(api_url + '/saveforlater', {
  //       product_id,
  //       user_id,
  //       quantity,
  //       price,
  //     }, {
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded'
  //       },
  //       transformRequest: [(data, headers) => {
  //         const params = new URLSearchParams();
  //         for (const key in data) {
  //           params.append(key, data[key]);
  //         }
  //         return params.toString();
  //       }],
  //     });
  //     const check_res = response.data.status;
  
  //     if (check_res) {
  //       if (check_res === 2) {
  //        alert(response.data.message);
  //         window.location.reload();
  //     }else{
  //       handleCartremove1(cart_id)
  //       window.location.reload();
  //     }
  //   }
  //   } catch (error) {
  //     console.error("Error adding to cart:", error);
  //   }
  // };

  const handleSaveToCart = async (cart_id, id, price1, qty) => {
    try {
      const product_id = id; 
      const user_id = localStorage.getItem('admin_id'); 
      const quantity = qty;
      const price = price1;
  
      const api_url = localStorage.getItem('api_url');
      const response = await axios.post(api_url + '/saveforlater', {
        product_id,
        user_id,
        quantity,
        price,
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest: [(data, headers) => {
          const params = new URLSearchParams();
          for (const key in data) {
            params.append(key, data[key]);
          }
          return params.toString();
        }],
      });
  
      const check_res = response.data.status;
  
      if (check_res) {
        if (check_res === "2") {
          alert(response.data.message);
          window.location.reload();
        } else {
          handleCartremove1(cart_id);
          window.location.reload();
        }
      }
    } catch (error) {
      console.error("Error adding to save for later:", error);
    }
  };

  const handleCartremove = async (id,products_id,quant) => {

    try {

      //get product details
      const api_url = localStorage.getItem('api_url');
      const product_id = products_id;
      const response2 = await axios.get(api_url + '/admingetProduct/'+product_id)
      const res2 = response2.data.product;
    
      // update quantity
      const remain_qty = res2.qty + quant;
      console.log(remain_qty)
      // const response1 = await axios.post(api_url + '/updateproductquantity', {
      //   product_id,
      //   remain_qty,
      // }, {
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded'
      //   },
      //   transformRequest: [(data, headers) => {
      //     const params = new URLSearchParams();
      //     for (const key in data) {
      //       params.append(key, data[key]);
      //     }
      //     return params.toString();
      //   }],
      // });

      const check_res2 = response2.data.status;
       
      if(check_res2){

        const productss_id = id; 
        const user_id = localStorage.getItem('admin_id'); 
        const response = await axios.get(api_url + '/removecart/'+productss_id)
        const check_res = response.data.status;
        if (check_res) {
          alert('Cart removed successfully');
          window.location.reload();
        }
      }

     
    } catch (error) {
      console.error("Error adding to cart:", error);
    }

  };

  const handleCartremove1 = async (cart_id) => {
    const api_url = localStorage.getItem('api_url');
    const response = await axios.get(api_url + '/removecart/'+cart_id)
    const check_res = response.data.status;
    if (check_res) {
      alert('Cart removed successfully');
      window.location.reload();
    }
  }

  const handleUploadPrescription = async (cart_id, file) => {
    try {
      // Check if the file type is either image or PDF
      if (file.type.startsWith('image/') || file.type === 'application/pdf') {
        // Create a new FormData object
        const formData = new FormData();
        // Append the file and cart_id to the FormData object
        formData.append('pic', file);
        formData.append('cart_id', cart_id); 
  
        // Send a POST request to your backend
        const api_url = localStorage.getItem('api_url');
        const response = await axios.post(api_url + '/uploadPrescription', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
  
        const check_res = response.data.status;
        if (check_res) {
          alert('Prescription successfully uploaded');
          window.location.reload();
        }
      } else {
        // File type is not supported
        alert("Unsupported file type. Please upload an image or PDF file.");
      }
    } catch (error) {
      alert("Error uploading prescription image:", error);
    }
  };

  const handleCouponSelection = (coupon) => {

    if (coupon.amount_upto >= cards1.ordertotal) {
      alert('This coupon can only be applied to orders above ₹' + coupon.amount_upto);
      return;
    }

    setSelectedCoupon(coupon);

    // Calculate discount amount
    const discount = Math.min(coupon.offer, coupon.amount_upto);
    setDiscountAmount(discount);

    // Update total amount
    const newTotalAmount = cards1.totalamt - discount;
    setTotalAmount(newTotalAmount);
  };

  const user_id = localStorage.getItem('admin_id');


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };


  useEffect(() => {
    const fetchCards = async () => {
      try {
        const user_id = localStorage.getItem('admin_id');
        const api_url = localStorage.getItem('api_url');
        const response = await axios.get(api_url + '/getusercarts/'+user_id);
        setCards(response.data.cartItems);
        setCards1({ totalamt: response.data.offer_amt_final, ordertotal: response.data.ordertotal,offer: response.data.offer_amt });
        setCoupons(response.data.coupon);

      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };

    fetchCards();
  }, []);

  return (
       <><div className='price_amount1'>
      {cards.length !== 0 && (
        cards.map((product) => (
          <div>
            <div className="order-card" key={product.id}>
              <div className="product-info">
                <img className="product-image" src={product.product_image} alt={product.product_name} />
                <div className="product-details">
                  <p className="product-name"><b>{product.product_name}</b></p>
                  <p>{product.brand_name}</p>
                          <p className="product-price">
                          {product.price_final < product.price ? (
                          <>
                          <span className='original-price'>₹{product.price}</span>
                          <span className='final-price'>₹{product.price_final}</span>
                          </>
                          ) : (
                          <span className='final-price'>₹{product.price}</span>
                          )}
                          </p>





                  <div className="quantity_details">
                    <div className="quantity-controls">
                      <button className="quantity-button" onClick={() => decreaseQuantity(product.product_id,product.quantity, product.cart_id,product.static_price)}>-</button>
                      <span className="quantity">{product.quantity}</span>
                      <button className="quantity-button" onClick={() => increaseQuantity(product.product_id, product.quantity, product.cart_id,product.static_price,product.pro_quantity)}>+</button>
                    </div>

                    <button className="product_save" onClick={() => handleSaveToCart(product.cart_id, product.product_id, product.price, product.quantity)}> {translate('save_for_later')}</button>
                    <button className="product_remove" onClick={() => handleCartremove(product.cart_id, product.product_id, product.quantity)}> {translate('remove')} </button>
                  </div>
                </div>
                <div className="del_days">
                  {/* <p>Delivery in 2 days  sat | ₹40 free  </p> */}
                </div>

                
                
              </div>
            </div>

            {
  product.p_image === 1 && (
    <div className="pres_up">
      <p className="upload_pres">{translate('upload_prescription')}</p>
      <input type="file" id="prescription" className="prescription" />
      <button
        className="btn btn-primary upload_btn"
        onClick={() =>
          handleUploadPrescription(
            product.cart_id,
            document.getElementById("prescription").files[0]
          )
        }
      >
        {" "}
        upload
      </button>
    </div>
  )
}
{
  product.p_image === 2 && (
    <p style={{'color':'green'}}>{translate('prescription_uploaded')}</p>
  )
}
            <hr></hr>

          </div>



        )))}
{cards.length !== 0 && (
  <button className="place_del" onClick={() => handleSavetrans_pament(totalAmount.toFixed(2) > 1 ? totalAmount.toFixed(2) : cards1.totalamt)}>{translate('place_order')}</button>
  )}

      {cards.length === 0 && (
        <p className="emptycart">{translate('cart_empty')}</p>
      )}


      <br></br>
      <p> </p>
    </div><br></br>
    
    <div className='payment_gate'>
      <div className='pay_detail'>
        <div className='coupon_app'>
          <p className='coupon_a'>{selectedCoupon ? `Coupon applied: ${selectedCoupon.coupon_code}` : 'No coupon applied'}</p>
         
        </div>

        <p><b>{translate('payment_summary')}</b></p>

        <div className='orde_tot'>
          <p>{translate('order_total')}</p>
          <p className='orde_pri'>₹ {cards1.ordertotal}</p>
        </div>
        <div className='orde_tot'>
          <p>{translate('items_discount')}</p>
          <p className='orde_pri1'>-₹ {cards1.offer}</p>
        </div>
        <div className='orde_tot'>
          <p>{translate('coupon_discount')}</p>
          <p className='orde_pri1'>-₹ {discountAmount.toFixed(2)}</p>
        </div>
        <div className='orde_tot'>
          <p>{translate('shipping')}</p>
          <p className='orde_pri2'>Free</p>
        </div>

        <hr />
        <div className='orde_tot'>
          <p><b>{translate('total')}</b></p>
            <p className='orde_pri3'>
            ₹ {totalAmount && totalAmount > 0 ? totalAmount.toFixed(2) : cards1.totalamt}
            </p>
        </div>
        <hr />
        <span className='save_amount'>{translate('you_will_save')} ₹ {discountAmount.toFixed(2)} {translate('available_coupons')}</span>

        <div className='coupon_selection'>
          <p><b>Available Coupons</b></p>
          {coupons.map(coupon => (
            <div key={coupon._id}>
              <input
                type="radio"
                id={coupon.coupon_code}
                name="coupon"
                value={coupon.coupon_code}
                checked={selectedCoupon && selectedCoupon.coupon_code === coupon.coupon_code}
                onChange={() => handleCouponSelection(coupon)}
              />
              <label htmlFor={coupon.coupon_code}>{coupon.coupon_code} - {coupon.offer}% off up to ₹{coupon.amount_upto}</label>
              <p>Valid Up To: {formatDate(coupon.valid_date)}</p>
            </div>
          ))}
        </div>
      </div>
    </div></>
    
  );
};

export default Ordercard;
