import React, { useState, useEffect } from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import Menu from './Menu';
import axios from 'axios';
import Prductslide from './Prductslide';
import Detail_tab from './Detail_tab';
import FavoriteCheckbox from './FavoriteCheckbox';
import Detail_rating from './Detail_rating';
import OrderCard from './Ordercard';
import Savecart from './Savecart';
import Addresschange from './Addresschange';
import { useTranslation } from './TranslationContext';

const Addcart = () => {
  const [carts, setCarts] = useState([]);
  const [api_url] = useState(localStorage.getItem('api_url'));
  const [user_id] = useState(localStorage.getItem('admin_id'));
  
  const { translate } = useTranslation(); // Using useTranslation hook in functional component

  const add_to_cart_api = () => {
    axios({
      method: 'get',
      url: `${api_url}/getusercarts/${user_id}`,
    }).then(res => {
      const data = res.data;
      setCarts(data.cartItems);
    });
  };

  useEffect(() => {
    add_to_cart_api();
  }, []);

  return (
    <div className="box">
      <Header_home />
      <br />
      <br />
      <Addresschange />
      <div className="social_container">
        <div className="rating_detail_page">
          <div className="rating"></div>
        </div>
      </div>
      <div className="container">
        <OrderCard />
      </div>
      <div className="price_amount2">
        <Savecart />
      </div>
      <br />
      <br />
      <div className="sell_best_product">
        <h2>{translate('recently')}</h2>
        <Prductslide />
        <br />
        <br />
        <br />
      </div>
      <Footer />
    </div>
  );
};

export default Addcart;
